// import  './Footer.css'



// const Footer = () => {

//         const currentYear = new Date().getFullYear();

//   return (
//     <>
//      <div className="container-fluid">
//      <div className="row rowfooter">
            
//                 <div className="col-lg-3 col-sm-6  seciones-footer f1">
//                         <h4 className=' font-light text-md justify-center mb-2'>SEDE CENTRAL</h4>
//                         <p className=' txt-footer mb-2'><i className="fa-solid fa-location-dot mr-2"></i>  9 de Julio 647 (oeste)- San Juan</p>
//                         <p className=' txt-footer mb-2'><a href="mailto:info@asijemin.org.ar" ><i className="fa-solid fa-envelope mr-2"></i> info@asijemin.org.ar</a></p>
//                 </div>

//                 <div className="col-lg-3 col-sm-6  seciones-footer f2">
//                         <h4 className=' font-light text-md justify-center mb-2 '>DELEGACIÓN REGIONAL SUR</h4>
//                         <p className=' txt-footer mb-2'><i className="fa-solid fa-location-dot mr-2"></i> Mitre 963, Puerto San Julián, Santa Cruz</p>
//                         <p className=' txt-footer mb-2'> 
//                         <div className='waweb'>
//                                 <a href="https://api.whatsapp.com/send?phone=2645319544" target="_blank" ><i className="fa-brands fa-whatsapp mr-2"></i> 2645319544 </a>
//                         </div>
//                         </p>
//                         <p className=' txt-footer mb-2'><a href="mailto:administracion.secsur@asijemin.org.ar" ><i className="fa-solid fa-envelope mr-2"></i> administracion.secsur@asijemin.org.ar</a></p>
//                         <p className=' txt-footer mb-5'> 
//                         <a href="mailto:info@asijemin.org.ar" ><i className="fa-solid fa-envelope mr-2"></i> info@asijemin.org.ar</a>
//                         </p>


// {/* sede perito */}
                       
//                 </div>
//                 <div className='col-lg-3 col-sm-6  seciones-footer f3'>
//                 <h4 className=' font-light text-md justify-center mb-2'>SECCIONAL  PERITO MORENO</h4>
//                         <p className=' font-semibold mb-2 txt-footer'><i className="fa-solid fa-location-dot mr-2"></i> Avenida Perón 1730, Perito Moreno Santa Cruz</p>
//                         <p className=' txt-footer mb-2 '>
//                         <a href="mailto:sedeperitomoreno@asijemin.org.ar" ><i className="fa-solid fa-envelope mr-2"></i> sedeperitomoreno@asijemin.org.ar</a>

//                         </p>
//                         <p className=' txt-footer mb-2 '>
//                           <div className='waweb'>
//                                 <a href="https://api.whatsapp.com/send?phone=2648404006" target="_blank" ><i className="fa-brands fa-whatsapp mr-2 "></i> 2648404006 </a>
//                            </div>
                        
// </p>
//                 </div>
//                 <div className="col-lg-3 col-sm-6  seciones-footer f4">
//                         <h4 className=' font-light text-md justify-center mb-2'>DELEGACION REGIONAL CENTRO</h4>
//                         <p className=' txt-footer mb-2'><i className="fa-solid fa-location-dot mr-2"></i> Pedro de Valdivia 1964 (oeste) - San Juan</p>
//                         <p className='  mb-2 txt-footer'> <a href="mailto:info@asijemin.org.ar" ><i className="fa-solid fa-envelope mr-2"></i> info@asijemin.org.ar</a></p>
//                 </div>
//         </div>




//         {/* footer derechos */}

//         <div className="row">
//                 <div className='bg-footultimo h-14 '>
//                 <p className='text-center'>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong>  {currentYear}</p>

//                 </div>
//         </div>
//     </div>

        
     
//     </>
//   )
// }

// export default Footer




// import React from 'react';
// import './footer2.css';

// const Footer = () => {
//     const currentYear = new Date().getFullYear();

//     return (
//         <footer className="footer-container">
//             <div className="nav-footer">
//                 <a href="/convenios" className="footer-link">Convenios</a>
//                 <a href="/comunicados" className="footer-link">Comunicados</a>
//             </div>
//             <div className="footer-sections">
//                 <div className="footer-column">
//                     <h4>SEDE CENTRAL</h4>
//                     <p><i className="fa-solid fa-location-dot"></i> 9 de Julio 647 (oeste)- San Juan</p>
//                     <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//                 </div>
//                 <div className="footer-column">
//                     <h4>DELEGACIÓN REGIONAL SUR</h4>
//                     <p><i className="fa-solid fa-location-dot"></i> Mitre 963, Puerto San Julián, Santa Cruz</p>
//                     <p><a href="https://api.whatsapp.com/send?phone=2645319544" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i> 2645319544</a></p>
//                     <p><a href="mailto:administracion.secsur@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> administracion.secsur@asijemin.org.ar</a></p>
//                     <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//                 </div>
//                 <div className="footer-column">
//                     <h4>SECCIONAL PERITO MORENO</h4>
//                     <p><i className="fa-solid fa-location-dot"></i> Avenida Perón 1730, Perito Moreno Santa Cruz</p>
//                     <p><a href="mailto:sedeperitomoreno@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> sedeperitomoreno@asijemin.org.ar</a></p>
//                     <p><a href="https://api.whatsapp.com/send?phone=2648404006" target="_blank" rel="noopener noreferrer"><i className="fa-brands fa-whatsapp"></i> 2648404006</a></p>
//                 </div>
//                 <div className="footer-column">
//                     <h4>DELEGACION REGIONAL CENTRO</h4>
//                     <p><i className="fa-solid fa-location-dot"></i> Pedro de Valdivia 1964 (oeste) - San Juan</p>
//                     <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//                 </div>
//             </div>
//             <div className="footer-bottom">
//                 <p>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong> {currentYear}</p>
//             </div>
//         </footer>
//     );
// };

// export default Footer;



// import './footer2.css';

// const Footer = () => {
//   const currentYear = new Date().getFullYear();

//   return (
//     <>
//       <div className="footer-container">
//         <div className="footer-links">
//           <a href="/convenios" className="footer-link">Convenios</a>
//           <a href="/comunicados" className="footer-link">Comunicados</a>
//         </div>
//         <div className="footer-content">
//           <div className="footer-section">
//             <h4>SEDE CENTRAL</h4>
//             <p><i className="fa-solid fa-location-dot"></i> 9 de Julio 647 (oeste)- San Juan</p>
//             <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//           </div>
//           <div className="footer-section">
//             <h4>DELEGACIÓN REGIONAL SUR</h4>
//             <p><i className="fa-solid fa-location-dot"></i> Mitre 963, Puerto San Julián, Santa Cruz</p>
//             <p><i className="fa-brands fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=2645319544" target="_blank">2645319544</a></p>
//             <p><a href="mailto:administracion.secsur@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> administracion.secsur@asijemin.org.ar</a></p>
//             <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//           </div>
//           <div className="footer-section">
//             <h4>SECCIONAL PERITO MORENO</h4>
//             <p><i className="fa-solid fa-location-dot"></i> Avenida Perón 1730, Perito Moreno Santa Cruz</p>
//             <p><a href="mailto:sedeperitomoreno@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> sedeperitomoreno@asijemin.org.ar</a></p>
//             <p><i className="fa-brands fa-whatsapp"></i> <a href="https://api.whatsapp.com/send?phone=2648404006" target="_blank">2648404006</a></p>
//           </div>
//           <div className="footer-section">
//             <h4>DELEGACIÓN REGIONAL CENTRO</h4>
//             <p><i className="fa-solid fa-location-dot"></i> Pedro de Valdivia 1964 (oeste) - San Juan</p>
//             <p><a href="mailto:info@asijemin.org.ar"><i className="fa-solid fa-envelope"></i> info@asijemin.org.ar</a></p>
//           </div>
//         </div>
//         <div className="footer-bottom">
//           <p>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong> {currentYear}</p>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Footer;



import './footer2.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer-container">
      <div className="footer-links">
        <a href="/" className="footer-link">Convenios</a>
        <a href="/comunicados" className="footer-link">Comunicados</a>
      </div>
      
      <div className="footer-top-line"></div>
      
      <div className="footer-content">
        
        <div className="footer-section">
          <h4>SEDE NACIONAL:</h4>
          <p>9 de Julio Oeste 647, Capital, San Juan | </p>
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
       
        <div className="footer-section">
          <h4>DEL REGIONAL CENTRO:</h4>
          <p>Pedro de Valdivia 1964 (oeste) - San Juan | </p>
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
        <div className="footer-section">
          <h4>DEL REGIONAL SUR - PTO. SAN JULIÁN:</h4>
          <p>Mitre 963, Puerto San Julián, Santa Cruz | </p>
          <a href="mailto:administracion.secsur@asijemin.org.ar">administracion.secsur@asijemin.org.ar</a> | 
          <a href="mailto:info@asijemin.org.ar">info@asijemin.org.ar</a>
        </div>
        <div className="footer-section">
          <h4>DEL REGIONAL SUR - PERITO MORENO:</h4>
          <p>Av. Perón 1730, Perito Moreno, Santa Cruz | </p>
          <a href="mailto:sedeperitomoreno@asijemin.org.ar">sedeperitomoreno@asijemin.org.ar</a>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; Todos los derechos reservados <strong>ASIJEMIN</strong> {currentYear}</p>
      </div>
    </div>
  );
};

export default Footer;
