import React from 'react';
import './InstallPromptModal.css'; 


const InstallPromptModal = ({ onInstallClick, onClose }) => {
  return (
    <div className="install-prompt-modal">
      <div className="modal-content">
      

        <h2 className='apptitlemodal'>Beneficios App | ASIJEMIN</h2>
        <p>Puedes instalar nuestra aplicación y accederla rápidamente desde tu dispositivo.</p>
        <div className="modal-actions">
          <button className="install-button" onClick={onInstallClick}>Instalar</button>
          <button className="cancel-btn" onClick={onClose}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default InstallPromptModal;
