// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";

// const AnuncioComponent = ({ item }) => {
//   const wifi =
//     item.wifi === "si" ? (
//       <div className="flex flex-col items-center mb-4">
//         <i
//           className="fa-solid fa-wifi mb-1"
//           data-tip="¡Esta es una red WiFi!"
//           data-for="wifiTooltip"
//         ></i>
//         <span className=" text-[10px]">WiFi</span>
//       </div>
//     ) : null;

//   // END: ed8c6549bwf9

//   const estacionamiento =
//     item.estacionamiento === "si" ? (
//       <div className="flex flex-col items-center icon-container mb-4">
//         <i
//           class="fa-solid fa-square-parking mb-1"
//           data-tooltip="Estacionamiento"
//         ></i>
//         <span className=" text-[10px]">Estacionamiento</span>
//       </div>
//     ) : null;
    
    
//     const year = parseInt(item.validez.split('-')[2]);

//   const aireacc =
//     item.aire === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           width="16"
//           height="16"
//           id="air-conditioner"
//           fill="none"
//           viewBox="0 0 24 24"
//         >
//           <path
//             d="M21.43 14.794c-.389 0-.648-.259-.648-.647s.26-.647.647-.647c.389 0 .777-.259.777-.647V5.865c0-1.165-.906-2.07-2.07-2.07H4.864c-1.165 0-2.07.905-2.07 2.07v6.988c0 .388.387.776.776.776.388 0 .647.26.647.648s-.389.517-.647.517c-1.165 0-2.071-.906-2.071-1.941V5.865C1.5 4.053 3.053 2.5 4.865 2.5h15.14c1.942 0 3.495 1.553 3.495 3.365v6.988c0 1.035-.906 1.941-2.07 1.941z"
//             fill="currentColor"
//           ></path>
//           <path
//             d="M22.853 10.782H2.147c-.388 0-.647-.259-.647-.647s.259-.647.647-.647h20.706c.388 0 .647.259.647.647s-.259.647-.647.647zM3.572 19.194c-.388 0-.647-.259-.647-.647s.259-.647.647-.647c1.165 0 2.07-.906 2.07-2.07v-2.848c0-.388.26-.647.648-.647s.647.259.647.647V15.7c0 1.941-1.553 3.494-3.365 3.494zM9.004 21.912c-.388 0-.647-.26-.647-.647 0-.389.389-.647.647-.647.389 0 .777-.389.777-.777v-6.859c0-.388.259-.647.647-.647s.647.259.647.647v6.86c0 1.164-.906 2.07-2.07 2.07zM21.43 19.194c-1.942 0-3.366-1.553-3.366-3.365v-2.847c0-.388.26-.647.648-.647s.647.259.647.647V15.7c0 1.165.905 2.07 2.07 2.07.388 0 .647.26.647.648s-.259.776-.647.776zM15.995 21.912a2.044 2.044 0 01-2.07-2.07v-6.86c0-.388.259-.647.647-.647s.647.259.647.647v6.86c0 .387.388.776.776.776.389 0 .647.258.647.647 0 .388-.388.647-.647.647zM19.358 7.547a1.035 1.035 0 100-2.07 1.035 1.035 0 000 2.07z"
//             fill="currentColor"
//           ></path>
//         </svg>

//         <span className=" text-[10px]">ACC</span>
//       </div>
//     ) : null;

//   const cajaFuerte =
//     item.boveda === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           width="16"
//           height="16"
//           id="safe-box"
//           fill="none"
//           viewBox="0 0 16 18"
//         >
//           <path
//             d="M2.667 3.7v3.2m0 2.133v3.2m0 2.667v1.6m10.666-1.6v1.6m-3.2-5.867a2.133 2.133 0 110-4.266 2.133 2.133 0 010 4.266zM1.6 1.033h12.8c.589 0 1.066.478 1.066 1.067v11.733c0 .59-.477 1.067-1.066 1.067H1.6c-.59 0-1.067-.478-1.067-1.067V2.1c0-.59.478-1.067 1.067-1.067z"
//             stroke="currentColor"
//             stroke-width="1.067"
//             stroke-linecap="round"
//           ></path>
//         </svg>

//         <span className=" text-[10px]">Caja Fuerte</span>
//       </div>
//     ) : null;

//   const piscina =
//     item.piscina === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" viewBox="0 0 24 24">
//           <path d="M22 21c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.46.27-1.08.64-2.19.64-1.11 0-1.73-.37-2.18-.64-.37-.23-.6-.36-1.15-.36s-.78.13-1.15.36c-.46.27-1.08.64-2.19.64v-2c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.23.59.36 1.15.36v2zm0-4.5c-1.11 0-1.73-.37-2.18-.64-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36-.56 0-.78.13-1.15.36-.45.27-1.07.64-2.18.64s-1.73-.37-2.18-.64c-.37-.22-.6-.36-1.15-.36s-.78.13-1.15.36c-.47.27-1.09.64-2.2.64v-2c.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36.56 0 .78-.13 1.15-.36.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36s.78-.13 1.15-.36c.45-.27 1.07-.64 2.18-.64s1.73.37 2.18.64c.37.22.6.36 1.15.36v2zM8.67 12c.56 0 .78-.13 1.15-.36.46-.27 1.08-.64 2.19-.64 1.11 0 1.73.37 2.18.64.37.22.6.36 1.15.36s.78-.13 1.15-.36c.12-.07.26-.15.41-.23L10.48 5C8.93 3.45 7.5 2.99 5 3v2.5c1.82-.01 2.89.39 4 1.5l1 1-3.25 3.25c.31.12.56.27.77.39.37.23.59.36 1.15.36z" />
//           <circle cx="16.5" cy="5.5" r="2.5" />
//         </svg>

//         <span className=" text-[10px]">Piscina</span>
//       </div>
//     ) : null;

//   const gym =
//     item.gimnasio === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           version="1.1"
//           id="Capa_1"
//           x="0px"
//           y="0px"
//           width="16"
//           height="16"
//           viewBox="0 0 70.529 70.529"
//           style={{ enableBackground: "new 0 0 70.529 70.529" }} // Convertir la cadena en objeto
//         >
//           <path
//             d="M66.421,29.17h-1.607v-4.652c0-2.343-1.907-4.25-4.25-4.25h-2.861v-1.557c0-2.343-1.907-4.25-4.25-4.25h-3.155
// 	c-2.343,0-4.25,1.907-4.25,4.25v10.442H24.48V18.711c0-2.343-1.907-4.25-4.25-4.25h-3.155c-2.343,0-4.25,1.907-4.25,4.25v2.894
// 	h-2.86c-2.343,0-4.25,1.907-4.25,4.25v3.883H4.107C1.843,29.737,0,31.644,0,33.987v3.122c0,2.343,1.843,4.25,4.107,4.25h1.607v4.652
// 	c0,2.343,1.907,4.25,4.25,4.25h2.86v1.557c0,2.343,1.907,4.25,4.25,4.25h3.155c2.343,0,4.25-1.907,4.25-4.25v-11.01h21.568v11.01
// 	c0,2.343,1.907,4.25,4.25,4.25h3.155c2.343,0,4.25-1.907,4.25-4.25v-2.894h2.861c2.343,0,4.25-1.907,4.25-4.25v-3.882h1.607
// 	c2.265,0,4.107-1.907,4.107-4.25V33.42C70.529,31.077,68.686,29.17,66.421,29.17z M4.107,38.859c-0.886,0-1.607-0.785-1.607-1.75
// 	v-3.122c0-0.965,0.721-1.75,1.607-1.75h1.607v6.622H4.107z M9.965,47.761c-0.965,0-1.75-0.785-1.75-1.75V25.854
// 	c0-0.965,0.785-1.75,1.75-1.75h2.86v23.656H9.965z M21.98,51.818c0,0.965-0.785,1.75-1.75,1.75h-3.155
// 	c-0.965,0-1.75-0.785-1.75-1.75V18.711c0-0.965,0.785-1.75,1.75-1.75h3.155c0.965,0,1.75,0.785,1.75,1.75V51.818z M24.48,38.308
// 	v-6.655h21.568v6.655H24.48z M55.203,51.818c0,0.965-0.785,1.75-1.75,1.75h-3.155c-0.965,0-1.75-0.785-1.75-1.75V18.711
// 	c0-0.965,0.785-1.75,1.75-1.75h3.155c0.965,0,1.75,0.785,1.75,1.75V51.818z M62.314,44.674c0,0.965-0.785,1.75-1.75,1.75h-2.861
// 	V22.768h2.861c0.965,0,1.75,0.785,1.75,1.75V44.674z M68.029,36.542c0,0.965-0.721,1.75-1.607,1.75h-1.607V31.67h1.607
// 	c0.886,0,1.607,0.785,1.607,1.75V36.542z"
//           />
//         </svg>

//         <span className=" text-[10px]">Gimnasio</span>
//       </div>
//     ) : null;

//   const salaReunion =
//     item.salareuniones === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           width="16"
//           height="16"
//           id="business-center"
//           fill="none"
//           viewBox="0 0 25 24"
//         >
//           <path
//             d="M15.265 16.865c-.21 0-.419-.21-.523-.419-.105-.314.104-.523.418-.628l4.294-.942c.314-.105.523.104.628.418.105.315-.105.524-.419.629l-4.293.942h-.105zM11.496 11.525c-.523 0-1.152-.314-1.361-.943-.314-.733 0-1.675.628-2.094l2.827-1.57c.629-.42 1.362-.524 1.99-.21l4.188 1.78c.314.105.42.42.314.733-.104.314-.418.42-.733.314l-4.188-1.78c-.314-.104-.733-.104-1.047.105l-2.827 1.466c-.21.105-.314.523-.21.838.105.209.315.314.524.314l2.932-1.047c.314-.105.523 0 .628.314.105.314 0 .523-.314.628l-2.932 1.047c-.105 0-.314.105-.419.105z"
//             fill="currentColor"
//           ></path>
//           <path
//             d="M11.496 20.111c-.524 0-.943-.21-1.257-.628l-2.722-3.56H5.632c-.21 0-.524-.21-.524-.524 0-.314.315-.523.524-.523h2.094c.21 0 .314.104.419.21l2.932 3.769c.21.21.419.314.628.21.21-.106.419-.42.419-.734v-.628c0-.21.105-.314.21-.419.104-.105.314-.105.523 0l.105.105c.21.104.523.104.733-.105.21-.21.418-.524.418-.838 0-.314.21-.523.524-.523h.419c.21 0 .524-.21.628-.42.105-.313.105-.732-.104-.941l-2.514-3.98c-.209-.209-.104-.628.105-.732.21-.21.524-.105.733.104l2.618 3.874c.419.629.419 1.362.21 1.99-.315.628-.838 1.047-1.571 1.047-.105.524-.42.943-.733 1.152a2.013 2.013 0 01-1.257.419c0 .733-.419 1.256-1.047 1.57-.21.105-.419.105-.628.105zM5.737 10.268a.576.576 0 01-.524-.314c-.105-.314 0-.523.21-.733l3.769-1.675a2.107 2.107 0 012.199.314l.314.314c.21.21.21.524 0 .733-.105.314-.419.314-.628.105l-.314-.314c-.21-.21-.733-.314-1.047-.105l-3.77 1.675h-.21z"
//             fill="currentColor"
//           ></path>
//           <path
//             d="M4.9 16.551H3.223c-.314 0-.524-.21-.524-.523 0-.315.21-.524.524-.524h1.675c.105 0 .105 0 .21-.105.104-.104.104-.21.104-.314V9.012c0-.105 0-.21-.104-.314 0 0-.105-.105-.21-.105H3.224c-.314.105-.524-.105-.524-.419s.21-.523.524-.523h1.675c.314 0 .628.104.943.314.314.21.418.628.418 1.047v6.178a1.6 1.6 0 01-.418 1.047c-.315.21-.629.314-.943.314zM20.397 16.551c-.314 0-.629-.105-.838-.314-.314-.21-.419-.628-.419-1.047V9.012c0-.419.21-.838.419-1.047.21-.21.628-.314.942-.314h1.676c.314 0 .523.21.523.523 0 .314-.21.524-.523.524H20.5c-.104 0-.104 0-.209.104-.105.105-.105.21-.105.315v6.073c0 .104 0 .21.105.314 0 0 .105.105.21.105h1.675c.314 0 .523.21.523.523 0 .314-.21.524-.523.524l-1.78-.105zM12.648 18.226c-.105 0-.314-.104-.419-.21l-.838-.941a.506.506 0 010-.733c.21-.21.524-.21.733 0l.838.942c.21.21.21.524 0 .733-.105.21-.21.21-.314.21zM14.742 16.865c-.105 0-.314-.105-.419-.21l-1.256-1.36a.506.506 0 010-.733c.21-.21.523-.21.733 0l1.256 1.36c.21.21.21.524 0 .734-.104.104-.21.21-.314.21z"
//             fill="currentColor"
//           ></path>
//         </svg>

//         <span className=" text-[10px]">Sala Reunion</span>
//       </div>
//     ) : null;

//   const traslados =
//     item.traslados === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="transportation" viewBox="0 0 128 128">
//           <path
//             fill="currentColor"
//             d="M76.652,86.768c-0.141,5.92,4.771,11.082,10.719,11.245c6.044,0.166,10.933-4.582,11.051-10.741C98.54,81,94.079,76.283,87.872,76.128C81.868,75.973,76.793,80.783,76.652,86.768z M0,77.736c-0.017,5.477,2.703,8.601,7.846,9.147c1.617,0.175,4.456-1.278,5.036-2.687c4.528-11.057,9.935-15.664,18.784-15.281c8.685,0.389,14.233,5.757,17.517,16.929c0.062,0.183,0.22,0.346,0.839,1.281c2.974,0,6.05-0.11,9.102,0.023c5.768,0.254,10.724,0.933,12.403-7.426c1.442-7.144,9.513-11.099,16.829-10.797c7.266,0.304,13.845,5.142,16.272,12.084c0.67,1.892,1.132,3.858,1.645,5.66c10.493,0.96,14.104-3.058,11.766-12.814c-5.306-22.085-10.69-44.15-15.996-66.221c-1.211-5.06-4.01-7.663-9.491-7.635C64.846,0.11,37.14,0.028,9.429,0.073C2.878,0.082,0.073,2.841,0.062,9.392C-0.006,32.167,0.084,54.953,0,77.736z M79.908,11.217c12.391-1.234,12.391-1.234,14.785,10.122c0.552,2.594,0.952,5.219,1.549,7.801c4.405,18.846,5.036,16.897-14.492,16.683c-0.546-0.012-1.081-0.414-1.842-0.715V11.217z M46.192,11.245h24.445v34.347H46.192V11.245z M11.068,16.033c0.096-1.783,2.338-4.853,3.763-4.954c7.249-0.504,14.571-0.228,22.085-0.228v35.071c-7.48,0-14.222-0.023-20.964,0.014c-3.638,0.025-5.126-1.771-5.08-5.328C10.949,32.415,10.668,24.208,11.068,16.033z M19.792,87.284c0.158,5.771,5.61,10.949,11.305,10.73c6.016-0.228,10.758-5.255,10.634-11.281c-0.135-6.029-5.125-10.75-11.208-10.603C24.501,76.283,19.612,81.361,19.792,87.284z"
//           ></path>
//         </svg>

//         <span className=" text-[10px]">Traslados</span>
//       </div>
//     ) : null;

//   const equipaje =
//     item.equipaje === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           width="16"
//           height="16"
//           id="suitcase"
//           fill="none"
//           viewBox="0 0 24 26"
//         >
//           <path
//             clip-rule="evenodd"
//             d="M5 6.713a1.5 1.5 0 011.5-1.5h12a1.5 1.5 0 011.5 1.5v14.673a1.5 1.5 0 01-1.5 1.5h-12a1.5 1.5 0 01-1.5-1.5V6.713z"
//             stroke="currentColor"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//           ></path>
//           <path
//             d="M9.5 10.033h6M9.5 18.066h6M8 22.887V24.6M17 22.887V24.6M8 2h9M9.5 5.213V2M15.5 5.213V2"
//             stroke="currentColor"
//             stroke-linecap="round"
//             stroke-linejoin="round"
//           ></path>
//         </svg>

//         <span className=" text-[9px] text-center">Deposito de Equipaje</span>
//       </div>
//     ) : null;

//   const acustica =
//     item.salaacustica === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg
//           width="16"
//           height="16"
//           id="soundprofed-rooms"
//           viewBox="0 0 128 128"
//         >
//           <path
//             fill="currentColor"
//             d="M21.605,45.453C21.795,25.055,38.321,8.444,58.739,8.254C79.148,8.063,95.758,25.256,95.944,45.4c0.048,5.322,8.305,5.386,8.263,0.053C103.974,20.568,83.724,0.235,58.804,0.002C33.903-0.231,13.564,20.823,13.347,45.4C13.294,50.732,21.552,50.774,21.605,45.453z M0,66.063v9.5c0,2.75,2.233,4.993,4.995,4.993V61.048C2.233,61.048,0,63.29,0,66.063z M102.487,52.71H98.63c-4.56,0-8.257,3.693-8.257,8.253v3.468c-0.487-0.508-1.169-0.828-1.928-0.828h-5.046c-1.141,0-2.165,0.599-2.737,1.528l-1.746-7.5c-0.339-1.451-1.628-2.474-3.117-2.48c-1.501-0.003-2.799,1.024-3.138,2.483l-4.061,17.413l-6.456-27.688c-0.336-1.454-1.628-2.48-3.12-2.483c-1.498-0.003-2.793,1.026-3.132,2.483l-6.456,27.688l-4.061-17.413c-0.336-1.454-1.628-2.48-3.12-2.483c-1.498-0.003-2.793,1.024-3.132,2.483l-1.749,7.497c-0.572-0.929-1.593-1.528-2.734-1.528H29.59c-0.759,0-1.441,0.32-1.928,0.829v-3.469c0-4.56-3.698-8.253-8.263-8.253h-3.857c-4.56,0-8.258,3.693-8.258,8.253v19.688c0,4.56,3.697,8.263,8.258,8.263h3.857c4.565,0,8.263-3.703,8.263-8.263V68.124c0.487,0.509,1.169,0.829,1.928,0.829h3.35l1.392,5.969c0.34,1.46,1.634,2.486,3.129,2.484c1.495,0,2.787-1.026,3.123-2.484l1.663-7.134l4.061,17.412c0.34,1.454,1.634,2.48,3.129,2.48c1.495-0.003,2.784-1.03,3.123-2.484l6.456-27.687l6.456,27.69c0.342,1.457,1.637,2.483,3.132,2.48c1.496-0.003,2.784-1.03,3.123-2.484l4.059-17.409l1.663,7.134c0.339,1.457,1.634,2.486,3.132,2.484c1.495-0.003,2.784-1.03,3.123-2.484l1.392-5.969h3.347c0.76,0,1.441-0.32,1.928-0.828v12.526c0,4.56,3.698,8.263,8.257,8.263h3.857c4.56,0,8.258-3.703,8.258-8.263V60.963C110.745,56.403,107.047,52.71,102.487,52.71z M113.035,61.048v19.508c2.761,0,5-2.243,5-4.993v-9.5C118.035,63.29,115.797,61.048,113.035,61.048z"
//           ></path>
//         </svg>

//         <span className=" text-[9px] text-center">Sala Acustica</span>
//       </div>
//     ) : null;

//   const mascotas =
//     item.mascotas === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="pets" viewBox="0 0 24 24">
//           <path
//             fill="currentColor"
//             d="M4.8 15.5c-1.3 0-2.3-1.5-2.3-3.5s1-3.5 2.3-3.5c1.3 0 2.3 1.5 2.3 3.5s-1 3.5-2.3 3.5zm0-6c-.6 0-1.3 1-1.3 2.5s.7 2.5 1.3 2.5c.6 0 1.3-1 1.3-2.5s-.7-2.5-1.3-2.5zM9 9.5C7.7 9.5 6.7 8 6.7 6s1-3.5 2.3-3.5c1.3 0 2.3 1.5 2.3 3.5s-1 3.5-2.3 3.5zm0-6c-.6 0-1.3 1-1.3 2.5S8.4 8.5 9 8.5c.6 0 1.3-1 1.3-2.5S9.6 3.5 9 3.5zM19.2 14.9c-1.3 0-2.3-1.5-2.3-3.5s1-3.5 2.3-3.5c1.3 0 2.3 1.5 2.3 3.5s-1 3.5-2.3 3.5zm0-6c-.6 0-1.3 1-1.3 2.5s.7 2.5 1.3 2.5c.6 0 1.3-1 1.3-2.5s-.7-2.5-1.3-2.5zM15 9.5c-1.3 0-2.3-1.5-2.3-3.5s1-3.5 2.3-3.5c1.3 0 2.3 1.5 2.3 3.5s-1 3.5-2.3 3.5zm0-6c-.6 0-1.3 1-1.3 2.5s.7 2.5 1.3 2.5c.6 0 1.3-1 1.3-2.5s-.7-2.5-1.3-2.5zM12 21.5c-2.9 0-5.3-1.8-5.3-4.1 0-1.2.6-2.2 1.5-2.9.2-.1.3-.3.3-.6v-.1c0-1.9 1.6-3.5 3.5-3.5s3.5 1.6 3.5 3.5v.1c0 .2.1.5.3.6.9.7 1.5 1.7 1.5 2.9 0 2.3-2.4 4.1-5.3 4.1zm0-10.2c-1.4 0-2.5 1.1-2.5 2.5v.1c0 .5-.2 1.1-.7 1.4-.7.5-1.1 1.3-1.1 2.1 0 1.7 1.9 3.1 4.3 3.1s4.3-1.4 4.3-3.1c0-.8-.5-1.6-1.1-2.1-.4-.3-.7-.9-.7-1.4v-.1c0-1.4-1.1-2.5-2.5-2.5z"
//           ></path>
//         </svg>

//         <span className=" text-[9px] text-center">Mascotas</span>
//       </div>
//     ) : null;


//     const spa =
//     item.spa === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="spa" viewBox="0 0 24 24">
//         <path d="M20.8 21.534H3.2c-1.173 0-2.2-1.027-2.2-2.2V9.947c0-1.173 1.027-2.2 2.2-2.2h17.6c1.173 0 2.2 1.027 2.2 2.2v9.387c0 1.173-1.027 2.2-2.2 2.2zM3.2 9.214c-.44 0-.733.293-.733.733v9.387c0 .44.293.733.733.733h17.6c.44 0 .733-.293.733-.733V9.947c0-.44-.293-.733-.733-.733H3.2z" fill="currentColor"></path><path d="M5.547 9.213c-.44 0-.734-.293-.734-.733V3.2c0-.44.294-.733.734-.733s.733.293.733.733v5.28c0 .44-.293.733-.733.733zM18.453 9.213c-.44 0-.733-.293-.733-.733V3.2c0-.44.293-.733.733-.733s.733.293.733.733v5.28c0 .44-.293.733-.733.733z" fill="currentColor"></path><path d="M22.267 3.933H1.733C1.293 3.933 1 3.64 1 3.2s.293-.733.733-.733h20.534c.44 0 .733.293.733.733s-.293.733-.733.733zM10.533 19.333c-.44 0-.733-.293-.733-.733v-7.627c0-.44.293-.733.733-.733s.734.293.734.733V18.6c0 .44-.294.733-.734.733z" fill="currentColor"></path><path d="M11.267 16.253h-.734c-.44 0-.733-.293-.733-.733s.293-.734.733-.734h.734c.88 0 1.466-.733 1.466-1.613s-.587-1.613-1.466-1.613h-.734c-.44 0-.733-.294-.733-.587s.293-.733.733-.733h.734c1.613 0 2.933 1.32 2.933 3.08a2.942 2.942 0 01-2.933 2.933zM6.133 19.334H4.666c-.44 0-.733-.294-.733-.734s.293-.733.733-.733h1.467c.44 0 .733-.294.733-.733 0-.734-.44-1.174-1.173-1.76-.733-.734-1.76-1.467-1.76-2.934 0-1.32 1.027-2.2 2.2-2.2H7.6c.44-.146.733.293.733.733s-.293.587-.733.587H6.133c-.44 0-.733.44-.733.88 0 .734.44 1.174 1.173 1.76.733.587 1.76 1.32 1.76 2.934 0 1.173-1.027 2.2-2.2 2.2zM19.333 19.333c-.44 0-.733-.293-.733-.733v-6.16c0-.44-.294-.734-.734-.734s-.733.294-.733.734v6.16c0 .44-.293.733-.733.733s-.733-.293-.733-.733v-6.16c0-1.32 1.026-2.2 2.2-2.2 1.173 0 2.2.88 2.2 2.2v6.16c0 .44-.294.733-.734.733z" fill="currentColor"></path><path d="M19.333 16.253H16.4c-.44 0-.733-.293-.733-.733s.293-.733.733-.733h2.933c.44 0 .734.293.734.733s-.294.733-.734.733z" fill="currentColor"></path>
//         </svg>

//         <span className=" text-[9px] text-center">Spa</span>
//       </div>
//     ) : null;

//     const playa =
//     item.playa === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="pets" viewBox="0 0 24 24">
//         <path d="M18.4 18h-.2c-1.9-.9-4.1-1.3-6.2-1.2-2.2-.1-4.3.4-6.2 1.2-.3.1-.5 0-.7-.2-.1-.3 0-.5.2-.7 2.1-.9 4.4-1.4 6.6-1.3 2.2-.1 4.5.4 6.6 1.3.3.1.4.4.2.7.1.1-.1.2-.3.2z" fill="currentColor"></path>
//         </svg>

//         <span className=" text-[9px] text-center">Playa</span>
//       </div>
//     ) : null;

//     const mreducida =
//     item.mreducida === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="pets" viewBox="0 0 24 24">
//         <path d="M17 20.988c-.6 0-1.2-.3-1.5-.9l-1-2h-3c-.9 0-1.6-.7-1.6-1.6v-5.5c0-.9.7-1.6 1.6-1.6.9 0 1.6.7 1.6 1.6v3.8h2.3c.6 0 1.2.3 1.5.9l1.5 2.9c.2.4.2.8.1 1.2-.1.4-.4.7-.8.9-.2.2-.5.3-.7.3zm-.6-1.4c.2.3.5.4.9.3.2-.1.3-.2.3-.3v-.4l-1.5-2.9c-.1-.2-.3-.3-.6-.3h-2.8c-.3 0-.5-.2-.5-.5v-4.5c0-.3-.3-.6-.6-.6s-.7.3-.7.6v5.4c0 .3.3.6.6.6h3.3c.2 0 .4.1.4.3l1.2 2.3z" fill="currentColor"></path>
//         <path d="M10.9 21.288c-1.8 0-3.6-.9-4.6-2.5-.7-1.2-.9-2.5-.5-3.8.4-1.3 1.2-2.5 2.5-3.1.2-.1.5 0 .7.2.1.2 0 .5-.2.7-1 .6-1.7 1.5-2 2.5-.3 1-.1 2.1.4 3.1 1.2 2 3.8 2.6 5.9 1.5.2-.1.5 0 .7.2.1.2 0 .5-.2.7-.9.3-1.8.5-2.7.5zM11.5 4.288c-1.3 0-2.4 1.1-2.4 2.4 0 1.3 1.1 2.4 2.5 2.4s2.5-1.1 2.5-2.4c0-1.3-1.2-2.4-2.6-2.4zm0 3.8c-.8 0-1.5-.6-1.5-1.4 0-.8.7-1.4 1.5-1.4s1.5.6 1.5 1.4c0 .8-.6 1.4-1.5 1.4z" fill="currentColor"></path>
//         </svg>

//         <span className=" text-[9px] text-center">Movilidad Redcida</span>
//       </div>
//     ) : null;

//     const desayuno =
//     item.desayuno === "si" ? (
//       <div className="flex items-center flex-col mb-4">
//         <svg width="16" height="16" id="pets" viewBox="0 0 24 24">
//         <path d="M9.014 5.296c-.12 0-.24 0-.36-.12a.58.58 0 010-.838c.36-.36.24-.48-.12-1.08-.36-.599-.958-1.558.12-2.516.24-.24.6-.36.84-.12s.24.599 0 .839c-.36.48-.24.599.12 1.079.359.599.838 1.558-.12 2.517-.12.12-.24.24-.48.24zM12.49 5.296c-.12 0-.24 0-.36-.12-.24-.24-.36-.599-.12-.839.36-.36.24-.48-.12-1.078-.24-.6-.84-1.559.12-2.518.24-.24.6-.24.839 0 .24.24.24.6 0 .84-.24.359-.24.479.12.958.36.6.959 1.559 0 2.518-.12.12-.36.24-.48.24zM15.94 5.296c-.119 0-.239 0-.359-.12-.24-.24-.36-.599-.12-.839.36-.36.24-.48-.12-1.078-.24-.6-.838-1.559.12-2.518.24-.24.6-.24.84 0s.24.6 0 .84c-.24.359-.24.479.12.958.359.6.958 1.559 0 2.518-.12.12-.36.24-.48.24zM12.49 23.276c-5.154 0-8.99-1.798-8.99-4.195 0-1.079.84-2.157 2.517-2.997.24-.12.6 0 .84.24.12.24 0 .6-.24.84-1.2.598-1.798 1.198-1.798 1.797-.12 1.559 3.116 3.117 7.671 3.117s7.792-1.558 7.792-2.997c0-.6-.6-1.319-1.799-1.798-.24-.12-.479-.48-.24-.839.12-.36.48-.48.84-.24 1.558.72 2.517 1.798 2.517 2.997-.12 2.277-3.956 4.075-9.11 4.075z" fill="currentColor"></path>
//         <path d="M12.431 20.4c-2.793 0-5.004-1.439-5.47-2.398-.349-.48-1.746-2.757-1.164-7.552 0-.36.35-.599.699-.48.349 0 .582.36.465.72-.582 4.555.931 6.593 1.048 6.593l.116.12c.117.36 1.862 1.798 4.423 1.798 2.56 0 4.19-1.319 4.423-1.798 0 0 0-.12.116-.12 0 0 1.513-2.038.931-6.713 0-.36.233-.6.466-.719.349 0 .582.24.698.48.582 4.794-.815 7.192-1.164 7.551-.582 1.079-2.793 2.517-5.587 2.517z" fill="currentColor"><path d="M9.014 5.296c-.12 0-.24 0-.36-.12a.58.58 0 010-.838c.36-.36.24-.48-.12-1.08-.36-.599-.958-1.558.12-2.516.24-.24.6-.36.84-.12s.24.599 0 .839c-.36.48-.24.599.12 1.079.359.599.838 1.558-.12 2.517-.12.12-.24.24-.48.24zM12.49 5.296c-.12 0-.24 0-.36-.12-.24-.24-.36-.599-.12-.839.36-.36.24-.48-.12-1.078-.24-.6-.84-1.559.12-2.518.24-.24.6-.24.839 0 .24.24.24.6 0 .84-.24.359-.24.479.12.958.36.6.959 1.559 0 2.518-.12.12-.36.24-.48.24zM15.94 5.296c-.119 0-.239 0-.359-.12-.24-.24-.36-.599-.12-.839.36-.36.24-.48-.12-1.078-.24-.6-.838-1.559.12-2.518.24-.24.6-.24.84 0s.24.6 0 .84c-.24.359-.24.479.12.958.359.6.958 1.559 0 2.518-.12.12-.36.24-.48.24zM12.49 23.276c-5.154 0-8.99-1.798-8.99-4.195 0-1.079.84-2.157 2.517-2.997.24-.12.6 0 .84.24.12.24 0 .6-.24.84-1.2.598-1.798 1.198-1.798 1.797-.12 1.559 3.116 3.117 7.671 3.117s7.792-1.558 7.792-2.997c0-.6-.6-1.319-1.799-1.798-.24-.12-.479-.48-.24-.839.12-.36.48-.48.84-.24 1.558.72 2.517 1.798 2.517 2.997-.12 2.277-3.956 4.075-9.11 4.075z" fill="currentColor"></path></path>
//         <path d="M12.49 13.927c-3.835 0-6.712-1.439-6.712-3.476 0-2.038 2.877-3.477 6.712-3.477 3.836 0 6.713 1.439 6.713 3.476 0 2.038-2.877 3.477-6.713 3.477zm0-5.754c-3.236 0-5.513 1.199-5.513 2.277 0 1.08 2.397 2.278 5.513 2.278 3.117 0 5.514-1.199 5.514-2.277 0-1.08-2.277-2.278-5.514-2.278z" fill="currentColor"></path>
//         <path d="M17.045 12.968c-.12 0-.36-.12-.48-.24l-.48-.48c-.958-.838-2.277-1.318-3.595-1.198-1.319-.12-2.637.36-3.716 1.319l-.48.48a.58.58 0 01-.839 0 .58.58 0 010-.84l.48-.48c1.318-1.198 2.877-1.677 4.555-1.677 1.558-.12 3.236.479 4.435 1.558l.48.48c.239.239.239.599 0 .838 0 .24-.24.24-.36.24z" fill="currentColor"></path>
//         </svg>

//         <span className=" text-[9px] text-center">Desayuno</span>
//       </div>
//     ) : null;

//   return (
//     <>
//       <Helmet>
//         <title> {item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//           <div className="relative">
//             <img src={item.imagen} alt="" className="w-full h-auto" />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8  px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2 "></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div
//             className="bg-[#e0e0e0]  flex items-center"
//             style={{ height: "auto" }}
//           >
//             <div className="w-3/4" >
//             <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]" >
//               {item.nombre}
//             </h2>
//           </div>

//             {/* <div className="w-1/4 flex items-center justify-left h-10">
//             <h2 className="text-[#707173]">
//               <CantidadIconos cantidad={item.estrellas} />
//             </h2>
//           </div> */}
//             {/* <div className={item.tipo === "alojamiento" ? "w-3/4" : "w-full"}>
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div> */}

//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion}{" "}
//               - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {/* <p >Descripción: {item.descripcion}</p> */}
              
//               {item.descripcion.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {cajaFuerte}
//                   {piscina}
//                   {gym}
//                   {salaReunion}
//                   {traslados}
//                   {equipaje}
//                   {acustica}
//                   {mascotas}
//                   {spa}
//                   {playa}
//                   {mreducida}
//                   {desayuno}
                  
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">

//                 {item.descuento !== 0 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                    <p className="text-slate-200 px-4 py-2">
                   
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
                    
//                       </p> 
//                   </div>
//                 )}

//                 {item.telefono.toString().length > 4 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap.toString().length > 4 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                  )}

             
//               {year > 2020 && (
//               <div className="h-20  bg-slate-500 border-b border-white">
//                 <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                 <p className="text-slate-200 px-4 py-2">
//                   <i className="fa-regular fa-calendar-days mr-2"></i>{" "}
//                   {item.validez}
//                 </p>
//               </div>
//             )}

//                 <div className="h-20  bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;














// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";


// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />

//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   // (Repite el mismo patrón para los demás íconos)

//   return (
//     <>
//       <Helmet>
//         <title> {item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//           <div className="relative">
//             <img src={item.imagen} alt={item.nombre || 'Imagen del convenio'} className="w-full h-auto" />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2 "></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i> {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>

                
//               </div>
//             </div>
//           </div>


// aca va el carrusel 





//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;





















// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";

// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;


//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   // Función para renderizar las imágenes del carrusel
//   // const renderCarousel = () => {
//   //   if (item.imagen && item.imagen.length > 1) {
//   //     return (
//   //       <div id="default-carousel" className="relative w-full" data-carousel="slide">
//   //         <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
//   //           {item.imagen.slice(1).map((img, index) => (
//   //             <div key={index} className="block duration-700 ease-in-out" data-carousel-item>
//   //               <img
//   //                 src={img}
//   //                 className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
//   //                 alt={`Imagen ${index + 1}`}
//   //               />
//   //             </div>
//   //           ))}
//   //         </div>
//   //         <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//   //           {item.imagen.slice(1).map((_, index) => (
//   //             <button
//   //               key={index}
//   //               type="button"
//   //               className="w-3 h-3 rounded-full"
//   //               aria-current={index === 0 ? "true" : "false"}
//   //               aria-label={`Slide ${index + 1}`}
//   //               data-carousel-slide-to={index}
//   //             ></button>
//   //           ))}
//   //         </div>
//   //         <button
//   //           type="button"
//   //           className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//   //           data-carousel-prev
//   //         >
//   //           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//   //             <svg
//   //               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//   //               aria-hidden="true"
//   //               xmlns="http://www.w3.org/2000/svg"
//   //               fill="none"
//   //               viewBox="0 0 6 10"
//   //             >
//   //               <path
//   //                 stroke="currentColor"
//   //                 stroke-linecap="round"
//   //                 stroke-linejoin="round"
//   //                 stroke-width="2"
//   //                 d="M5 1 1 5l4 4"
//   //               />
//   //             </svg>
//   //             <span className="sr-only">Previous</span>
//   //           </span>
//   //         </button>
//   //         <button
//   //           type="button"
//   //           className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//   //           data-carousel-next
//   //         >
//   //           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//   //             <svg
//   //               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//   //               aria-hidden="true"
//   //               xmlns="http://www.w3.org/2000/svg"
//   //               fill="none"
//   //               viewBox="0 0 6 10"
//   //             >
//   //               <path
//   //                 stroke="currentColor"
//   //                 stroke-linecap="round"
//   //                 stroke-linejoin="round"
//   //                 stroke-width="2"
//   //                 d="m1 9 4-4-4-4"
//   //               />
//   //             </svg>
//   //             <span className="sr-only">Next</span>
//   //           </span>
//   //         </button>
//   //       </div>
//   //     );
//   //   }
//   //   return null;
//   // };


//   const renderCarousel = () => {
//     return (
//       <div id="default-carousel" className="relative w-full" data-carousel="slide">
//         <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
//           {item.imagen.map((img, index) => (
//             <div key={index} className={`duration-700 ease-in-out ${index === 0 ? 'block' : 'hidden'}`} data-carousel-item>
//               <img
//                 src={img}
//                 className="absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
//                 alt={`Imagen ${index + 1}`}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//           {item.imagen.map((_, index) => (
//             <button
//               key={index}
//               type="button"
//               className="w-3 h-3 rounded-full"
//               aria-current={index === 0 ? "true" : "false"}
//               aria-label={`Slide ${index + 1}`}
//               data-carousel-slide-to={index}
//             ></button>
//           ))}
//         </div>
//         <button
//           type="button"
//           className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           data-carousel-prev
//         >
//           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//             <svg
//               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 stroke-width="2"
//                 d="M5 1 1 5l4 4"
//               />
//             </svg>
//             <span className="sr-only">Previous</span>
//           </span>
//         </button>
//         <button
//           type="button"
//           className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           data-carousel-next
//         >
//           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//             <svg
//               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 stroke-linecap="round"
//                 stroke-linejoin="round"
//                 stroke-width="2"
//                 d="m1 9 4-4-4-4"
//               />
//             </svg>
//             <span className="sr-only">Next</span>
//           </span>
//         </button>
//       </div>
//     );
//   };
  

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//           <div className="relative">
//             <img
//               src={item.imagen[0]}
//               alt={item.nombre || "Imagen del convenio"}
//               className="w-full h-auto"
//             />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i>{" "}
//               {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i>{" "}
//                       {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i>{" "}
//                       {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i>{" "}
//                       {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>
//               </div>
//             </div>
//           </div>

//           {renderCarousel()}
//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;












// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";
// import { useState } from "react";

// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

// console.log('item de el objeto por props', item)

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );



//   const [currentIndex, setCurrentIndex] = useState(0);

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? item.imagen.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === item.imagen.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   // Función para renderizar el carrusel
//   const renderCarousel = () => {
//     return (
//       <div className="relative w-full" data-carousel="slide">
//       <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
//         {item.imagen.map((img, index) => (
//           <div
//             key={index}
//             className={`${
//               index === currentIndex ? "block" : "hidden"
//             } duration-700 ease-in-out`}
//             data-carousel-item
//           >
//             <img
//               src={img}
//               className="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
//               alt={`Imagen ${index + 1}`}
//             />
//           </div>
//         ))}
//       </div>
//       <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//         {item.imagen.map((_, index) => (
//           <button
//             key={index}
//             type="button"
//             className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
//             aria-current={index === currentIndex ? "true" : "false"}
//             aria-label={`Slide ${index + 1}`}
//             onClick={() => goToSlide(index)}
//           ></button>
//         ))}
//       </div>
//       <button
//         type="button"
//         className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={prevSlide}
//       >
//         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//           <svg
//             className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M5 1 1 5l4 4"
//             />
//           </svg>
//           <span className="sr-only">Previous</span>
//         </span>
//       </button>
//       <button
//         type="button"
//         className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={nextSlide}
//       >
//         <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//           <svg
//             className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="m1 9 4-4-4-4"
//             />
//           </svg>
//           <span className="sr-only">Next</span>
//         </span>
//       </button>
//     </div>
//     );
//   };

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//           <div className="relative">
//             <img
//               src={item.imagen[0]}
//               alt={item.nombre || "Imagen del convenio"}
//               className="w-full h-auto"
//             />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i> {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>
//               </div>
//             </div>
//           </div>

//           {renderCarousel()}

//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;











// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";
// import { useState } from "react";

// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

//   console.log('item de el objeto por props', item);

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   const [currentIndex, setCurrentIndex] = useState(0);

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? item.imagen.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === item.imagen.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   // Función para renderizar el carrusel
//   const renderCarousel = () => {
//     if (!item.imagen || item.imagen.length === 0) {
//       return <p>No hay imágenes disponibles</p>;
//     }

//     return (
//       <div className="relative w-full" data-carousel="slide">
//         <div className="relative h-56 overflow-hidden rounded-lg md:h-96">
//           {item.imagen.map((img, index) => (
//             <div
//               key={index}
//               className={`${
//                 index === currentIndex ? "block" : "hidden"
//               } duration-700 ease-in-out`}
//               data-carousel-item
//             >
//               <img
//                 src={img}
//                 className="absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
//                 alt={`Imagen ${index + 1}`}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//           {item.imagen.map((_, index) => (
//             <button
//               key={index}
//               type="button"
//               className={`w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
//               aria-current={index === currentIndex ? "true" : "false"}
//               aria-label={`Slide ${index + 1}`}
//               onClick={() => goToSlide(index)}
//             ></button>
//           ))}
//         </div>
//         <button
//           type="button"
//           className="absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           onClick={prevSlide}
//         >
//           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//             <svg
//               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M5 1 1 5l4 4"
//               />
//             </svg>
//             <span className="sr-only">Previous</span>
//           </span>
//         </button>
//         <button
//           type="button"
//           className="absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           onClick={nextSlide}
//         >
//           <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
//             <svg
//               className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m1 9 4-4-4-4"
//               />
//             </svg>
//             <span className="sr-only">Next</span>
//           </span>
//         </button>
//       </div>
//     );
//   };

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//           <div className="relative">
//             <img
//               src={item.imagen[0]}
//               alt={item.nombre || "Imagen del convenio"}
//               className="w-full h-auto"
//             />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i> {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>
//               </div>
//             </div>
//           </div>

//           {renderCarousel()}

//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;





// import React, { useState } from 'react';
// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";
// import Carousel from '../Carrusel/Carrusel.jsx'; // Importamos el nuevo componente Carousel

// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3]">
//         <div className="w-1/2">
//         <div className="relative">
//         <img
//                src={item.imagen[0]}
//                alt={item.nombre || "Imagen del convenio"}
//                className="w-full h-auto"
//              />
//              {item.destacado && (
//                <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//                </div>
//              )}
//            </div>
//           </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i> {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />

//                 </div>
//                 {/* <Carousel images={item.imagen} /> */}
//                 {item.imagen.length > 1 && <Carousel images={item.imagen.slice(1)} />}
//                 {/* {item.imagen.length > 1 && <Carousel images={item.imagen} />} */}
//               </div>
//             </div>

//           </div>

//         </div>

//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;




// import React, { useState } from 'react';
// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";
// import Carousel from '../Carrusel/Carrusel.jsx'; // Importamos el nuevo componente Carousel

// const AnuncioComponent = ({ item }) => {
//   const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className="flex bg-[#f3f3f3] main-content">
//         <div className="w-1/2">
//           <div className="relative">
//             <img
//               src={item.imagen[0]}
//               alt={item.nombre || "Imagen del convenio"}
//               className="w-full h-auto"
//             />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                   {/* Agrega los demás íconos aquí */}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 {year > 2020 && (
//                   <div className="h-20 bg-slate-500 border-b border-white">
//                     <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-regular fa-calendar-days mr-2"></i> {item.validez}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>

//                 {/* Renderizamos el carrusel solo si hay más de una imagen */}
//                 {item.imagen.length > 1 && <Carousel images={item.imagen.slice(1)} />}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;





// import React, { useState } from 'react';
// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
// import { Card, Text } from "@tremor/react";
// import { Helmet } from "react-helmet";
// import "./AlojamientoComponent.css";
// import { MdAir } from "react-icons/md";
// import Carousel from '../Carrusel/Carrusel.jsx'; 

// const AnuncioComponent = ({ item }) => {
//   const [isModalOpen, setModalOpen] = useState(false);
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const openModal = (index) => {
//     setCurrentIndex(index);
//     setModalOpen(true);
//     document.body.classList.add('modal-open'); // Agregar la clase al abrir el modal
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//     document.body.classList.remove('modal-open'); // Remover la clase al cerrar el modal
//   };

//   const wifi = item?.wifi === "si" && (
//     <div className="flex flex-col items-center mb-4">
//       <i className="fa-solid fa-wifi mb-1"></i>
//       <span className="text-[10px]">WiFi</span>
//     </div>
//   );

//   const estacionamiento = item?.estacionamiento === "si" && (
//     <div className="flex flex-col items-center icon-container mb-4">
//       <i className="fa-solid fa-square-parking mb-1"></i>
//       <span className="text-[10px]">Estacionamiento</span>
//     </div>
//   );

//   const aireacc = item?.aire === "si" && (
//     <div className="flex items-center flex-col mb-4">
//       <MdAir />
//       <span className="text-[10px]">ACC</span>
//     </div>
//   );

//   return (
//     <>
//       <Helmet>
//         <title>{item.nombre} | Accion Social ASIJEMIN</title>
//       </Helmet>

//       <div className={`flex bg-[#f3f3f3] main-content`}>
//         <div className="w-1/2">
//           <div className="relative">
//             <img
//               src={item.imagen[0]}
//               alt={item.nombre || "Imagen del convenio"}
//               className="w-full h-auto"
//             />
//             {item.destacado && (
//               <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
//                 <i className="fas fa-crown text-base mr-2"></i>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="w-1/2">
//           <div className="bg-[#e0e0e0] flex items-center" style={{ height: "auto" }}>
//             <div className="w-3/4">
//               <h2 className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                 {item.nombre}
//               </h2>
//             </div>
//             {item.tipo === "alojamiento" && (
//               <div className="w-1/4 flex items-center justify-left h-10">
//                 <h2 className="text-[#707173]">
//                   <CantidadIconos cantidad={item.estrellas} />
//                 </h2>
//               </div>
//             )}
//           </div>

//           <div className="bg-[#eae8e8] h-10">
//             <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//               <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
//             </h2>
//           </div>

//           <div className="flex">
//             <div className="w-3/5 p-4 bg-slate-50">
//               {item.descripcion?.split("\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               <div className="mt-28">
//                 <Card className="grid grid-cols-4">
//                   {wifi}
//                   {estacionamiento}
//                   {aireacc}
//                 </Card>
//               </div>
//             </div>

//             <div className="w-2/5 px-2">
//               <div className="mb-4">
//                 {item.descuento !== 0 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
//                     </p>
//                   </div>
//                 )}

//                 {item.telefono?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap?.toString().length > 4 && (
//                   <div className="h-10 bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                 )}

//                 <div className="h-20 bg-slate-500 border-t border-white flex items-center justify-center">
//                   <PDFGenerator item={item} />
//                 </div>

//                 {item.imagen.length > 1 && (
//                   <Carousel
//                     images={item.imagen.slice(1)}
//                     onOpenModal={openModal}
//                   />
//                 )}
//               </div>
//             </div>
//           </div>
//         </div>

//         {isModalOpen && (
//           <div className="modal" onClick={closeModal}>
//             <span className="modal-close" onClick={closeModal}>×</span>
//             <img src={item.imagen[currentIndex]} alt={`Imagen ${currentIndex + 1}`} />
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default AnuncioComponent;



import React, { useState } from 'react';
import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
import CantidadIconos from "../CantidadIconos/CantidadIconos.jsx";
import { Card } from "@tremor/react";
import { Helmet } from "react-helmet";
import "./AlojamientoComponent.css";
import "./AlojamientoComponentTailwinds.css"
import { MdAir } from "react-icons/md";
import Carousel from '../Carrusel/Carrusel.jsx'; 

const AlojamientoComponent = ({ item }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalOpen(true);
    document.body.classList.add('modal-open'); // Agregar la clase al abrir el modal
  };

  const closeModal = () => {
    setModalOpen(false);
    document.body.classList.remove('modal-open'); // Remover la clase al cerrar el modal
  };

  const wifi = item?.wifi === "si" && (
    <div className="tw-flex tw-flex-col tw-items-center tw-mb-4">
      <i className="fa-solid fa-wifi tw-mb-1"></i>
      <span className="tw-text-[10px]">WiFi</span>
    </div>
  );

  const estacionamiento = item?.estacionamiento === "si" && (
    <div className="tw-flex tw-flex-col tw-items-center icon-container tw-mb-4">
      <i className="fa-solid fa-square-parking tw-mb-1"></i>
      <span className="tw-text-[10px]">Estacionamiento</span>
    </div>
  );

  const aireacc = item?.aire === "si" && (
    <div className="tw-flex tw-items-center tw-flex-col tw-mb-4">
      <MdAir />
      <span className="tw-text-[10px]">ACC</span>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>{item.nombre} | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className={`tw-flex tw-bg-[#f3f3f3]  ${isModalOpen ? 'blur-bg' : ''}`}>
        <div className="tw-w-1/2">
          <div className="relative">
            <img
              src={item.imagen[0]}
              alt={item.nombre || "Imagen del convenio"}
              className="tw-w-full tw-h-auto"
            />
            {item.destacado && (
              <div className="tw-absolute tret tw-top-0 tw-right-0 tw-transform tw-rotate-30 tw-h-8 tw-px-2 tw-py-2 tw-rounded">
                <i className="fas fa-crown text-base tw-mr-2"></i>
              </div>
            )}
          </div>
        </div>

        <div className="tw-w-1/2">
          <div className="tw-bg-[#e0e0e0] tw-flex tw-items-center" style={{ height: "auto" }}>
            <div className="tw-w-3/4">
              <h2 className="tw-text-3xl tw-font-bold tw-pt-4 tw-p-4 tw-text-[#707173]">
                {item.nombre}
              </h2>
            </div>
            {item.tipo === "alojamiento" && (
              <div className="tw-w-1/4 tw-flex tw-items-center tw-justify-left tw-h-10">
                <h2 className="tw-text-[#707173]">
                  <CantidadIconos cantidad={item.estrellas} />
                </h2>
              </div>
            )}
          </div>

          <div className="tw-bg-[#eae8e8] tw-h-10">
            <h2 className="tw-px-4 tw-text-[#707173] tw-font-normal tw-pt-[8px]">
              <i className="fa-solid fa-location-dot tw-mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
            </h2>
          </div>

          <div className="tw-flex">
            <div className="tw-w-3/5 tw-p-4 tw-bg-slate-50">
              {item.descripcion?.split("\n").map((linea, i) => (
                <p key={i}>{linea}</p>
              ))}
             <div className="tw-mt-28 tw-bg-white tw-border tw-border-black-400 tw-rounded-lg">
                  <Card className="tw-grid tw-grid-cols-4 tw-py-3 ">
                    {wifi}
                    {estacionamiento}
                    {aireacc}
                  </Card>
                </div>

            </div>

            <div className="tw-w-2/5 tw-px-2">
              <div className="tw-mb-4">
                {item.descuento !== 0 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-percent tw-mr-2"></i> {item.descuento}%
                    </p>
                  </div>
                )}

                {item.telefono?.toString().length > 4 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-phone tw-mr-2"></i> {item.telefono}
                    </p>
                  </div>
                )}

                {item.whatsaap?.toString().length > 4 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-brands fa-whatsapp tw-mr-2"></i> {item.whatsaap}
                    </p>
                  </div>
                )}

                <div className="tw-h-20 tw-bg-slate-500 tw-border-t tw-border-white tw-flex tw-items-center tw-justify-center">
                  <PDFGenerator item={item} />
                </div>

                {item.imagen.length > 1 && (
                  <Carousel
                    images={item.imagen.slice(1)}
                    onOpenModal={openModal}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {isModalOpen && (
          <div className="modal" onClick={closeModal}>
            <span className="modal-close" onClick={closeModal}>×</span>
            <img src={item.imagen[currentIndex]} alt={`Imagen ${currentIndex + 1}`} />
          </div>
        )}
      </div>
    </>
  );
};

export default AlojamientoComponent;