import React from 'react';
import { Helmet } from 'react-helmet';
import './Comunicados.css';
import ComunicadosLS from '../ComunicadosLS/ComunicadosLS';



const Comunicados = () => {
  // const { modo } = useModo(); // Acceder al estado 'modo' desde el contexto

  // const estilo = modo ? "oscuro" : "claro";
  // console.log(modo)
  return (
    <>
      <Helmet>
        <title>Comunicados | Accion Social ASIJEMIN</title>
      </Helmet>
      <div > {/* Aplicar la clase de estilo al contenedor principal */}

       <ComunicadosLS/>
      
      </div>
    </>
  );
}

export default Comunicados;
