import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import "./MobileBottomBar.css"; // CSS para este componente
import ModalLogin from "./ModalLogin"; // Importa el modal de inicio de sesión

const MobileBottomBar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado local para controlar el modal

  const openModal = () => {
    setIsModalOpen(true); // Abre el modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };

  return (
    <>
      <div className="mobile-bottom-bar">
        <NavLink to="/" className="mobile-bottom-link">
          <span>Convenios</span>
        </NavLink>
        <NavLink to="/comunicados" className="mobile-bottom-link">
          <span>Beneficios Personales</span>
        </NavLink>
        <div className="mobile-bottom-link" onClick={openModal}>
            <FaUser className="icon" />
            <span className="ises">Iniciar Sesión</span>
        </div>

      </div>

      {/* Renderiza el modal solo si el estado isModalOpen es true */}
      {isModalOpen && <ModalLogin isOpen={isModalOpen} onClose={closeModal} />}
    </>
  );
};

export default MobileBottomBar;
