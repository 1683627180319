
// import React, { useState } from 'react';
// import './Carrusel.css';

// const Carousel = ({ images }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === images.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   return (
//     <div className="carousel-container relative w-full">
//       <div className="carousel-wrapper relative h-56 overflow-hidden rounded-lg md:h-96">
//         {images.map((img, index) => (
//           <div
//             key={index}
//             className={`carousel-slide ${
//               index === currentIndex ? 'block' : 'hidden'
//             } duration-700 ease-in-out`}
//           >
//             <img
//               src={img}
//               className="carousel-image absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
//               alt={`Imagen ${index + 2}`}
//             />
//           </div>
//         ))}
//       </div>
//       <div className="carousel-indicators absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//         {images.map((_, index) => (
//           <button
//             key={index}
//             type="button"
//             className={`indicator-button w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
//             aria-current={index === currentIndex ? 'true' : 'false'}
//             aria-label={`Slide ${index + 2}`}
//             onClick={() => goToSlide(index)}
//           ></button>
//         ))}
//       </div>
//       <button
//         type="button"
//         className="carousel-prev absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={prevSlide}
//       >
//         <span className="carousel-prev-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
//           <svg
//             className="w-4 h-4 text-white"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="M5 1 1 5l4 4"
//             />
//           </svg>
//           <span className="sr-only">Anterior</span>
//         </span>
//       </button>
//       <button
//         type="button"
//         className="carousel-next absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//         onClick={nextSlide}
//       >
//         <span className="carousel-next-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
//           <svg
//             className="w-4 h-4 text-white"
//             aria-hidden="true"
//             xmlns="http://www.w3.org/2000/svg"
//             fill="none"
//             viewBox="0 0 6 10"
//           >
//             <path
//               stroke="currentColor"
//               strokeLinecap="round"
//               strokeLinejoin="round"
//               strokeWidth="2"
//               d="m1 9 4-4-4-4"
//             />
//           </svg>
//           <span className="sr-only">Siguiente</span>
//         </span>
//       </button>
//     </div>
//   );
// };

// export default Carousel;






// import React, { useState } from 'react';
// import './Carrusel.css';

// const Carousel = ({ images }) => {
//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isModalOpen, setIsModalOpen] = useState(false);

//   const prevSlide = () => {
//     const isFirstSlide = currentIndex === 0;
//     const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
//     setCurrentIndex(newIndex);
//   };

//   const nextSlide = () => {
//     const isLastSlide = currentIndex === images.length - 1;
//     const newIndex = isLastSlide ? 0 : currentIndex + 1;
//     setCurrentIndex(newIndex);
//   };

//   const goToSlide = (slideIndex) => {
//     setCurrentIndex(slideIndex);
//   };

//   const openModal = () => {
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//   };

//   return (
//     <>
//       <div className={`carousel-container relative w-full ${isModalOpen ? 'blur-bg' : ''}`}>
//         <div className="carousel-wrapper relative h-56 overflow-hidden rounded-lg md:h-96">
//           {images.map((img, index) => (
//             <div
//               key={index}
//               className={`carousel-slide ${
//                 index === currentIndex ? 'block' : 'hidden'
//               } duration-700 ease-in-out`}
//             >
//               <img
//                 src={img}
//                 className="carousel-image absolute block w-full h-full object-cover -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 cursor-pointer"
//                 alt={`Imagen ${index + 2}`}
//                 onClick={openModal}
//               />
//             </div>
//           ))}
//         </div>
//         <div className="carousel-indicators absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
//           {images.map((_, index) => (
//             <button
//               key={index}
//               type="button"
//               className={`indicator-button w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
//               aria-current={index === currentIndex ? 'true' : 'false'}
//               aria-label={`Slide ${index + 2}`}
//               onClick={() => goToSlide(index)}
//             ></button>
//           ))}
//         </div>
//         <button
//           type="button"
//           className="carousel-prev absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           onClick={prevSlide}
//         >
//           <span className="carousel-prev-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
//             <svg
//               className="w-4 h-4 text-white"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M5 1 1 5l4 4"
//               />
//             </svg>
//             <span className="sr-only">Anterior</span>
//           </span>
//         </button>
//         <button
//           type="button"
//           className="carousel-next absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
//           onClick={nextSlide}
//         >
//           <span className="carousel-next-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
//             <svg
//               className="w-4 h-4 text-white"
//               aria-hidden="true"
//               xmlns="http://www.w3.org/2000/svg"
//               fill="none"
//               viewBox="0 0 6 10"
//             >
//               <path
//                 stroke="currentColor"
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="m1 9 4-4-4-4"
//               />
//             </svg>
//             <span className="sr-only">Siguiente</span>
//           </span>
//         </button>
//       </div>

//       {isModalOpen && (
//         <div className="modal fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="relative">
//             <img src={images[currentIndex]} alt="Imagen en grande" className="max-w-full max-h-screen" />
//             <button
//               onClick={closeModal}
//               className="absolute top-2 right-2 text-white text-2xl"
//             >
//               &times;
//             </button>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default Carousel;





import React, { useState } from 'react';
import './Carrusel.css';

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add('blur-bg'); // Aplicar al body entero
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove('blur-bg'); // Remover del body entero
  };

  return (
    <>
      <div className={`carousel-container relative w-full ${isModalOpen ? 'blur-bg' : ''}`}>
        <div className="carousel-wrapper relative h-56 overflow-hidden rounded-lg md:h-96">
          {images.map((img, index) => (
            <div
              key={index}
              className={`carousel-slide ${
                index === currentIndex ? 'block' : 'hidden'
              } duration-700 ease-in-out`}
            >
              <img
                src={img}
                className="carousel-image "
                alt={`Imagen ${index + 2}`}
                onClick={openModal}
              />
            </div>
          ))}
        </div>
        <div className="carousel-indicators absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse">
          {images.map((_, index) => (
            <button
              key={index}
              type="button"
              className={`indicator-button w-3 h-3 rounded-full ${index === currentIndex ? 'bg-blue-500' : 'bg-gray-300'}`}
              aria-current={index === currentIndex ? 'true' : 'false'}
              aria-label={`Slide ${index + 2}`}
              onClick={() => goToSlide(index)}
            ></button>
          ))}
        </div>
        <button
          type="button"
          className="carousel-prev absolute top-0 left-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={prevSlide}
        >
          <span className="carousel-prev-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
            <svg
              className="w-4 h-4 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 1 1 5l4 4"
              />
            </svg>
            <span className="sr-only">Anterior</span>
          </span>
        </button>
        <button
          type="button"
          className="carousel-next absolute top-0 right-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
          onClick={nextSlide}
        >
          <span className="carousel-next-icon inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 group-hover:bg-white/50">
            <svg
              className="w-4 h-4 text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 6 10"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 9 4-4-4-4"
              />
            </svg>
            <span className="sr-only">Siguiente</span>
          </span>
        </button>
      </div>

      {isModalOpen && (
        <div className="modal ">
          <div className="relative">
            <img src={images[currentIndex]} alt="Imagen en grande" className="max-w-full max-h-screen" />
            <button
              onClick={closeModal}
              className=" text-2xl btnclose"
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Carousel;
