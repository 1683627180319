// import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import "./Categorias.css";

// const Categorias = () => {
//   const [categorias, setCategorias] = useState([]);
//   const [items, setItems] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchConvenios = async () => {
//       try {
//         const response = await axios.get('https://asijeminapis.website:4000/convenios');
//         if (Array.isArray(response.data)) {
//           setCategorias([...new Set(response.data.map(item => item.tipo))]);
//           setItems(response.data);
//         } else if (response.data.message) {
//           setItems(response.data.message);
//         } else {
//           setError('La respuesta de la API no contiene un listado de convenios.');
//         }
//       } catch (error) {
//         setError('No se pudo obtener la respuesta de la API.');
//         console.error('Error al obtener datos:', error);
//       }
//     };

//     fetchConvenios();
//   }, []);

//   const filteredItems = selectedCategory 
//     ? items.filter(item => item.tipo === selectedCategory)
//     : items;

//   if (error) {
//     return <div>{error}</div>;
//   }

//   return (
//     <div className="categorias-container">
//       <div className="categorias-list">
//         <button 
//           onClick={() => setSelectedCategory(null)} 
//           className={`categoria-button ${selectedCategory === null ? 'active' : ''}`}
//         >
//           Todas
//         </button>
//         {categorias.map((categoria, index) => (
//           <button 
//             key={index}
//             onClick={() => setSelectedCategory(categoria)}
//             className={`categoria-button ${selectedCategory === categoria ? 'active' : ''}`}
//           >
//             {categoria}
//           </button>
//         ))}
//       </div>

//       <div className="items-container">
//         {filteredItems.map((item) => (
//           <div className="item-card" key={item._id}>
//             <div className="item-image">
//               <img src={item.imagen[0]} alt={item.nombre} />
//             </div>
//             <div className="item-details">
//               <h5>{item.nombre}</h5>
//               <p>{item.descripcion}</p>
//               <Link to={`/convenios/${item._id}`} className="item-link">
//                 Saber Más <i className="fas fa-arrow-right-long"></i>
//               </Link>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Categorias;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";

const Categorias = () => {
  const { tipoCategoria } = useParams(); // Obtenemos la categoría desde la URL
  const [convenios, setConvenios] = useState([]);
  const [filteredConvenios, setFilteredConvenios] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://asijeminapis.website:4000/convenios");
        if (Array.isArray(response.data.message)) {
          setConvenios(response.data.message);
        } else {
          console.error("Los datos obtenidos no son un array:", response.data);
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tipoCategoria) {
      const conveniosFiltrados = convenios.filter((convenio) => convenio.tipo === tipoCategoria);
      setFilteredConvenios(conveniosFiltrados);
    }
  }, [tipoCategoria, convenios]);

  return (
    <div className="row container-notas">
      {filteredConvenios.length > 0 ? (
        filteredConvenios.map((item) => (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-3 conetenedor-cards" key={item._id}>
            <div className="card card-anuncios mx-2 w-100 cards-principales" style={{ maxWidth: "427px" }}>
              <div className="contentimg ">
                <img src={item.imagen[0]} alt={item.nombre} className="card-img top" />
                {item.destacado && (
                  <p className='etiqueta-recomendados'><i className="fa-solid fa-crown"></i>Recomendado</p>
                )}
              </div>

              <div className="card-body">
                <div className="titulo-card-principal">
                  <h5 className="card-title">{item.nombre}</h5>
                </div>

                <p className="card-text txtcard">{item.descripcion}</p>
                <Link to={`/convenios/${item._id}`} className="subtitle_line">Saber Más <i className="fas fa-arrow-right-long"></i></Link>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p>No se encontraron elementos para esta categoría.</p>
      )}
    </div>
  );
};

export default Categorias;
