const CantidadIconos = ({ cantidad }) => {
    // Estilos CSS para el ícono (si es necesario)
    const iconoEstilos = {
      // Agrega estilos personalizados si los necesitas
    };
  
    return (
      <div className="tw-flex">
        {Array.from({ length: cantidad }).map((_, index) => (
          <div key={index} className="tw-mr-2">
            <i className="fa-regular fa-star calificacion" style={iconoEstilos}></i>
          </div>
        ))}
      </div>
    );
  };
export default CantidadIconos;
