


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './TopFive.css';
import { useNavigate } from 'react-router-dom';

const TopFive = () => {
  const [topFiveData, setTopFiveData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:4000/favoritos/mas-guardados');
        setTopFiveData(response.data.message);
      } catch (error) {
        console.error('Error al obtener los datos del top five:', error);
      }
    };

    fetchData();
  }, []);

  if (topFiveData.length === 0) {
    return <div>Cargando...</div>;
  }

  const handleNameClick = (convenioId) => {
    navigate(`/convenios/${convenioId}`);
  };

  return (
    <div>
      <div className="contenedortop5">
        {topFiveData[0] && (
          <div className="cont1">
            <img 
              src={Array.isArray(topFiveData[0].convenio[0].imagen) ? topFiveData[0].convenio[0].imagen[0] : topFiveData[0].convenio[0].imagen} 
              alt="" 
            />
            <p className='etiqueta-img'>
              <i className="fas fa-circle me-2 small fw-bold"></i>
              {topFiveData[0].convenio[0].provincia}
            </p>
            <h2 
              className='title-noticia' 
              onClick={() => handleNameClick(topFiveData[0].convenio[0]._id)} 
            >
              {topFiveData[0].convenio[0].nombre}
            </h2>
            {topFiveData[0].convenio[0].destacado && (
              <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                <i className="fas fa-crown text-base mr-2 "></i>
              </div>
            )}
          </div>
        )}

        <div className="cont2">
          {topFiveData[1] && (
            <div className="avsuperior">
              <img 
                src={Array.isArray(topFiveData[1].convenio[0].imagen) ? topFiveData[1].convenio[0].imagen[0] : topFiveData[1].convenio[0].imagen} 
                alt="" 
              />
              <p className='etiqueta-imgsuperior'>
                <i className="fas fa-circle me-2 small fw-bold"></i>
                {topFiveData[1].convenio[0].provincia}
              </p>
              <h2 
                className='title-noticia-superior' 
                onClick={() => handleNameClick(topFiveData[1].convenio[0]._id)} 
              >
                {topFiveData[1].convenio[0].nombre}
              </h2>
              {topFiveData[1].convenio[0].destacado && (
                <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                  <i className="fas fa-crown text-base mr-2 "></i>
                </div>
              )}
            </div>
          )}

          {topFiveData[2] && (
            <div className="infder">
              <img 
                src={Array.isArray(topFiveData[2].convenio[0].imagen) ? topFiveData[2].convenio[0].imagen[0] : topFiveData[2].convenio[0].imagen} 
                alt="" 
              />
              <p className='etiqueta-infder'>
                <i className="fas fa-circle me-2 small fw-bold"></i>
                {topFiveData[2].convenio[0].provincia}
              </p>
              <h2 
                className='title-noticia-derecha' 
                onClick={() => handleNameClick(topFiveData[2].convenio[0]._id)} 
              >
                {topFiveData[2].convenio[0].nombre}
              </h2>
              {topFiveData[2].convenio[0].destacado && (
                <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                  <i className="fas fa-crown text-base mr-2 "></i>
                </div>
              )}
            </div>
          )}

          {topFiveData[3] && (
            <div className="iniz">
              <img 
                src={Array.isArray(topFiveData[3].convenio[0].imagen) ? topFiveData[3].convenio[0].imagen[0] : topFiveData[3].convenio[0].imagen} 
                alt="" 
              />
              <p className='etiqueta-infiz'>
                <i className="fas fa-circle me-2 small fw-bold"></i>
                {topFiveData[3].convenio[0].provincia}
              </p>
              <h2 
                className='title-noticia-izquierda' 
                onClick={() => handleNameClick(topFiveData[3].convenio[0]._id)} 
              >
                {topFiveData[3].convenio[0].nombre}
              </h2>
              {topFiveData[3].convenio[0].destacado && (
                <div className="absolute tret top-0 right-0 transform -rotate-30 h-8 px-2 py-2 rounded">
                  <i className="fas fa-crown text-base mr-2 "></i>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopFive;
