import React, { useEffect, useState } from "react";

import '../../index.css'
import './ComunicadosLS.css'
import { Link } from 'react-router-dom';

// const apiUrl = process.env.REACT_APP_API_URL;
const urli=process.env.REACT_APP_API_URL
const API_CONVENIOS = urli+'comunicados'

const ComunicadosLS = () => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // Realiza una solicitud GET a la API para obtener la lista de elementos
    fetch(API_CONVENIOS)
      .then((response) => {       
         if (!response.ok) {
          throw new Error("No se pudo obtener la respuesta de la API.");
        }
        return response.json();
      })
      .then((data) => {
        setItems(data);
      })
      .catch((error) => {
        console.error("Error al obtener datos:", error);
      });
  }, []);


  return (
    <div>
      <p className=" font-light text-2xl ml-8">Comuicados de Prensa</p>

      <div className="container-comunicados grid grid-cols-1  sm:grid-cols-2 md:grid-cols-4 gap-4">
       
      {items.map(item => (
        <div className="card-marco mt-4 mb-12 " key={item._id}>
          <img src={item.imagen} alt="" className=" img-r"/>
          <h3 className="text-xl text-black mb-3 ml-2 font-bold titulo">{item.nombre}</h3>
          <p className="text-black mb-2 ml-2 descripcion-txt">{item.descripcion}</p>
          <button className="ml-2">leer mas</button>
        </div>

))}
        
      </div>
    </div>
  );
};

export default ComunicadosLS;
