// import MarcoContainer from "../MarcoContainer/MarcoContainer"
// import TopFive from "../TopFive/TopFive"



// const Principales = () => {
//   return (
//     <>
//       <TopFive />
   
//        <MarcoContainer />
//     </>
//   )
// }

// export default Principales
   


import React, { useContext, useState, useEffect } from "react";
import MarcoContainer from "../MarcoContainer/MarcoContainer";
import TopFive from "../TopFive/TopFive";
import { SearchContext } from '../SearchContext/SearchContext';

const Principales = () => {
  const { searchTerm } = useContext(SearchContext);
  const [showTopFive, setShowTopFive] = useState(true);

  useEffect(() => {
    // Ocultar TopFive si hay algo en el searchTerm, mostrarlo si está vacío
    if (searchTerm) {
      setShowTopFive(false);
    } else {
      setShowTopFive(true);
    }
  }, [searchTerm]);

  return (
    <>
      {showTopFive && <TopFive />}
      <MarcoContainer />
    </>
  );
}

export default Principales;
