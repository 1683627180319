// import { Text} from "@tremor/react";
// import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
// import { Helmet } from 'react-helmet'


// const ServicioComponent =  ({ item }) => {

//   const year = parseInt(item.validez.split('-')[2]);

  
//     return (
//       <>
//    <Helmet>
//         <title> {item.nombre} | Accion Social ASIJEMIN</title>
//   </Helmet>

//         <div className="flex bg-[#f3f3f3]">
//           <div className="w-1/2">
//             <div className="relative">
//               <img src={item.imagen} alt="" className="w-full h-auto" />
//               {item.destacado && (
//                 <div className="absolute top-0 right-0 transform -rotate-30 bg-red-500 text-white px-2 py-1 rounded">
//                   Destacado
//                 </div>
//               )}
//             </div>
//           </div>
    
//           <div className="w-1/2">
//             <div className="bg-[#e0e0e0]  flex items-center" style={{ height: "auto" }}>
//               <div className="w-full">
//               <h2 style={{fontSize: 'fit-content'}} className="text-3xl font-bold pt-4 p-4 text-[#707173]">
//                   {item.nombre}
//               </h2>
              
//               </div>
    
           
//             </div>
    
//             <div className="bg-[#eae8e8] h-10">
//               <h2 className="px-4 text-[#707173] font-normal pt-[8px]">
//                 <i className="fa-solid fa-location-dot mr-2"></i> {item.direccion} -{" "}
//                 {item.provincia} - {item.localidad}
//               </h2>
//             </div>
    
//             <div className="flex">
//               <div className="w-3/5 p-4 bg-slate-50">
//                 {/* <p>{item.descripcion}</p> */}
//                 {item.descripcion.split("\\n").map((linea, i) => (
//                 <p key={i}>{linea}</p>
//               ))}
//               </div>
    
//               <div className="w-2/5 px-2">
//                 <div className="mb-4">
                 

//                 {item.descuento !== 0 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                    <p className="text-slate-200 px-4 py-2">
                   
//                       <i className="fa-solid fa-percent mr-2"></i> {item.descuento}%
                    
//                       </p> 
//                   </div>
//                 )}
    
//                 {/* {item.telefono.legenth > 5 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                  )} */}


//                 {item.telefono.toString().length > 4 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-solid fa-phone mr-2"></i> {item.telefono}
//                     </p>
//                   </div>
//                 )}

//                 {item.whatsaap.toString().length > 4 && (
//                   <div className="h-10  bg-slate-500 border-b border-white">
//                     <p className="text-slate-200 px-4 py-2">
//                       <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
//                     </p>
//                   </div>
//                  )}
    

//             {year > 2020 && (
//               <div className="h-20  bg-slate-500 border-b border-white">
//                 <Text className="subt px-4 py-2"> Validez de la oferta</Text>
//                 <p className="text-slate-200 px-4 py-2">
//                   <i className="fa-regular fa-calendar-days mr-2"></i>{" "}
//                   {item.validez}
//                 </p>
//               </div>
//             )}
    
//                   <div className="h-20  bg-slate-500 border-t border-white flex items-center justify-center">
//                     <PDFGenerator item={item} />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         </>
//       );
//     };
    
    
// export default ServicioComponent


import { Text } from "@tremor/react";
import PDFGenerator from "../PdfGenerator/PdfGenerator.jsx";
import { Helmet } from 'react-helmet';

const ServicioComponent = ({ item }) => {
  const year = item?.validez ? parseInt(item.validez.split('-')[2]) : null;

  return (
    <>
      <Helmet>
        <title> {item.nombre} | Accion Social ASIJEMIN</title>
      </Helmet>

      <div className="tw-flex tw-bg-[#f3f3f3]">
        <div className="tw-w-1/2">
          <div className="relative">
            <img src={item.imagen} alt={item.nombre || 'Imagen del convenio'} className="tw-w-full tw-h-auto" />
            {/* {item.destacado && (
              <div className="absolute top-0 right-0 transform -rotate-30 bg-red-500 text-white px-2 py-1 rounded">
                Destacado
              </div>
            )} */}


               {item.destacado && (
                  <div className="tw-absolute tret tw-top-0 tw-right-0 tw-transform tw-rotate-30 tw-h-8 tw-px-2 tw-py-2 tw-rounded">
                    <i className="fas fa-crown text-base tw-mr-2"></i>
                  </div>
                )}

          </div>
        </div>

        <div className="tw-w-1/2">
          <div className="tw-bg-[#e0e0e0] tw-flex tw-items-center" style={{ height: "auto" }}>
            <div className="tw-w-full">
              <h2 className="tw-text-3xl tw-font-bold tw-pt-4 tw-p-4 tw-text-[#707173]">
                {item.nombre}
              </h2>
            </div>
          </div>

          <div className="tw-bg-[#eae8e8] tw-h-10">
            <h2 className="tw-px-4 tw-text-[#707173] tw-font-normal tw-pt-[8px]">
              <i className="fa-solid fa-location-dot tw-mr-2"></i> {item.direccion} - {item.provincia} - {item.localidad}
            </h2>
          </div>

          <div className="tw-flex">
            <div className="tw-w-3/5 tw-p-4 tw-bg-slate-50">
              {item.descripcion?.split("\\n").map((linea, i) => (
                <p key={i}>{linea}</p>
              ))}
            </div>

            <div className="tw-w-2/5 tw-px-2">
              <div className="tw-mb-4">
                {item.descuento !== 0 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-percent tw-mr-2"></i> {item.descuento}%
                    </p>
                  </div>
                )}

                {item.telefono?.toString().length > 4 && (
                  <div className="tw-h-10 tw-bg-slate-500 tw-border-b tw-border-white">
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-solid fa-phone tw-mr-2"></i> {item.telefono}
                    </p>
                  </div>
                )}

                {item.whatsaap?.toString().length > 4 && (
                  <div className="h-10 bg-slate-500 border-b border-white">
                    <p className="text-slate-200 px-4 py-2">
                      <i className="fa-brands fa-whatsapp mr-2"></i> {item.whatsaap}
                    </p>
                  </div>
                )}

                {year > 2020 && (
                  <div className="tw-h-20 tw-bg-slate-500 tw-border-b tw-border-white">
                    <Text className="subt tw-px-4 tw-py-2"> Validez de la oferta</Text>
                    <p className="tw-text-slate-200 tw-px-4 tw-py-2">
                      <i className="fa-regular fa-calendar-days tw-mr-2"></i> {item.validez}
                    </p>
                  </div>
                )}

                <div className="tw-h-20 tw-bg-slate-500 tw-border-t tw-border-white tw-flex tw-items-center tw-justify-center">
                  <PDFGenerator item={item} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicioComponent;
