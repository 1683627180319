import React, { createContext, useState, useContext } from 'react';

const ModoContext = createContext();

const ModoProvider = ({ children }) => {
  const [modo, setModo] = useState(false); // Asegúrate de que el estado inicial sea 'false'

  const cambiarModo = () => {
    setModo(!modo); // Cambia el estado al valor contrario
  };

  return (
    <ModoContext.Provider value={{ modo, cambiarModo }}>
      {children}
    </ModoContext.Provider>
  );
};

const useModo = () => {
  return useContext(ModoContext);
};

export { ModoProvider, useModo };
