// import React, { useContext } from "react";
// import Logo from "./asijeminaniversario.svg";
// import { FaSearch } from "react-icons/fa";
// import { IoMdClose } from "react-icons/io";
// import { SearchContext } from '../SearchContext/SearchContext';
// import "./NavSuperiorMobile.css";

// const NavSuperiorMobile = () => {
//   const { searchTerm, setSearchTerm } = useContext(SearchContext);

//   const handleInputChange = (e) => {
//     setSearchTerm(e.target.value);
//   };

//   const clearSearch = () => {
//     setSearchTerm('');
//   };

//   return (
//     <div className="nav-mobile-container">
//       {/* Logo pequeño para móviles */}
//       <div className="nav-logo-mobile">
//         <a href="/">
//           <img src={Logo} alt="ASIJEMIN Logo" />
//         </a>
//       </div>

//       {/* Buscador principal */}
//       <div className={`search-container-mobile ${searchTerm ? "active" : ""}`}>
//         <FaSearch className="search-icon-mobile" />
//         <input
//           type="text"
//           value={searchTerm}
//           onChange={handleInputChange}
//           placeholder="Buscar convenios..."
//           className="search-input-mobile"
//         />
//         {searchTerm && (
//           <button className="clear-search-btn-mobile" onClick={clearSearch}>
//             <IoMdClose className="clear-icon-mobile" />
//           </button>
//         )}
//       </div>
//     </div>
//   );
// };

// export default NavSuperiorMobile;




import React, { useContext, useState, useEffect } from "react";
import Logo from "./asijeminaniversario.svg";
import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { SearchContext } from '../SearchContext/SearchContext';
import "./NavSuperiorMobile.css";

const NavSuperiorMobile = () => {
  const { searchTerm, setSearchTerm } = useContext(SearchContext);
  const [isVisible, setIsVisible] = useState(true); // Estado para manejar si el navbar es visible o no
  const [lastScrollY, setLastScrollY] = useState(window.scrollY); // Guarda la posición previa del scroll

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearSearch = () => {
    setSearchTerm('');
  };

  const controlNavbar = () => {
    if (window.scrollY > lastScrollY) {
      // Si el usuario scrollea hacia abajo, oculta el navbar
      setIsVisible(false);
    } else {
      // Si el usuario scrollea hacia arriba, muestra el navbar
      setIsVisible(true);
    }
    setLastScrollY(window.scrollY); // Actualiza la última posición del scroll
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar); // Limpia el evento cuando el componente se desmonta
    };
  }, [lastScrollY]);

  return (
    <div className={`nav-mobile-container ${isVisible ? 'visible' : 'hidden'}`}>
      {/* Logo pequeño para móviles */}
      <div className="nav-logo-mobile">
        <a href="/">
          <img src={Logo} alt="ASIJEMIN Logo" />
        </a>
      </div>

      {/* Buscador principal */}
      <div className={`search-container-mobile ${searchTerm ? "active" : ""}`}>
        <FaSearch className="search-icon-mobile" />
        <input
          type="text"
          value={searchTerm}
          onChange={handleInputChange}
          placeholder="Buscar convenios..."
          className="search-input-mobile"
        />
        {searchTerm && (
          <button className="clear-search-btn-mobile" onClick={clearSearch}>
            <IoMdClose className="clear-icon-mobile" />
          </button>
        )}
      </div>
    </div>
  );
};

export default NavSuperiorMobile;
